import React, { useRef, useState } from "react";
import { Form, Button, Card, Alert, Container } from "react-bootstrap";
import { useAuth } from "../../contexts/AuthContext";
import { fires, auth } from "../../firebase";
import { Link, useNavigate } from "react-router-dom";
import { isMobile } from "react-device-detect";
import firebase from "firebase";

export default function Home() {
  const emailRef = useRef();
  const passwordRef = useRef();
  const { login } = useAuth();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const maxW = isMobile ? "100%" : "30%";

  async function systemLogout() {
    if (auth.currentUser != null) {
      auth.signOut();
      window.location.href = "/";
    }
  }

  async function handleSubmit(e) {
    e.preventDefault();
    try {
      setError("");
      setLoading(true);
      const email = emailRef.current.value
      const pass = passwordRef.current.value
      await login(email, pass);
      if (auth.currentUser != null) {
        const userId = auth.currentUser.uid;
        fires
          .collection("users")
          .doc(userId)
          .get()
          .then((snapshot_user) => {
            const userExist = snapshot_user.exists;
            const userData = snapshot_user.data();
            if (userExist && userData.orgId != null) {
              const orgId = userData.orgId;
              if (orgId === "") {
                systemLogout();
              } else {
                //record log
                fires
                .collection("groups")
                .doc(orgId)
                .collection("logs")
                .add({
                  type: 'login',
                  email: email,
                  device: 'web',
                  status: 'approved',
                  timestamp: firebase.firestore.Timestamp.fromDate(new Date())
                }).then((docR) => {
                  navigate("/");
                })
              }
            } else {
              systemLogout();
            }
          });
      }
    } catch {
      setError("Login failed, please try again later");
    }

    setLoading(false);
  }

  return (
    <Container
      className="d-flex align-items-center justify-content-center"
      style={{
        minWidth: window.innerWidth,
        minHeight: window.innerHeight,
        backgroundImage:
          "url(https://images.unsplash.com/photo-1489549132488-d00b7eee80f1?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&q=80)",
      }}
    >
      <div className="w-100" style={{ maxWidth: maxW }}>
        <Card>
          <Card.Body>
            <h2 className="text-center mb-4">EventGo Admin</h2>
            {error && <Alert variant="danger">{error}</Alert>}
            <Form onSubmit={handleSubmit}>
              <Form.Group id="email" style={{ marginTop: "15px" }}>
                <Form.Label>Email</Form.Label>
                <Form.Control type="email" ref={emailRef} required />
              </Form.Group>
              <Form.Group id="password" style={{ marginTop: "15px" }}>
                <Form.Label>Password</Form.Label>
                <Form.Control type="password" ref={passwordRef} required />
              </Form.Group>
              <Button
                disabled={loading}
                className="w-100 border-0"
                type="submit"
                style={{
                  marginTop: "15px",
                  backgroundColor: "#F6AC19",
                  color: "#000000",
                  fontWeight: "bold",
                }}
              >
                Login
              </Button>
            </Form>
            <div className="w-100 text-center mt-3">
              <Link to="/forgot-password" style={{ color: "#F6AC19" }}>
                Forgot Password?
              </Link>
            </div>
          </Card.Body>
        </Card>
      </div>
    </Container>
  );
}
