import React from "react";
import { Button } from "react-bootstrap";
import { fires } from "../../firebase";
import "firebase/firestore";
import { getNow } from "../Helper";

export default function CheckinOne({ memberItm, id, checkinCount }) {
  return (
    <Button
      variant="dark"
      disabled={memberItm.checkinStatus}
      onClick={() => {
        if (!memberItm.checkinStatus) {
          // Checkin
          const checkinAlert = window.confirm(
            `你想要帮${memberItm.name}签到一张吗？`
          );
          if (checkinAlert && memberItm.email) {
            fires
              .collection("events")
              .doc(id)
              .collection("members")
              .doc(memberItm.email)
              .update({
                checkinCount: checkinCount + 1,
                checkinBy: "admin",
                checkinMethod: "Click",
                checkinTimestamp: getNow(),
              });
          }
        }
      }}
    >
      签到一张
    </Button>
  );
}
